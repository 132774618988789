.service-modal {
    .modal-dialog {
        margin: 1rem;
        max-width: 100vw;
    }

    .modal-content {
        padding-top: 6rem;
        padding-bottom: 6rem;
        text-align: center;

        h2 {
            font-size: 3rem;
            line-height: 3rem;
        }

        @media screen and (max-width: 600px) {
            h2 {
                font-size: 1.8rem;
            }
        }

        p.item-intro {
            font-style: italic;
            margin-bottom: 2rem;
            text-align: center;
            //font-family: $subheadings-font-family;
        }

        p {
            margin-bottom: 2rem;
            white-space: pre-line;
            text-align: left;
        }

        .service-carousel {
            margin-bottom: 2rem;
        }

        .service-modal-body, li {
            p, h3, h4, li {
                text-align: left;
            }

            a {
                margin-bottom: 1rem;
            }
        }
    }
    
    .close-modal {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        background-color: transparent;

        &:hover {
            opacity: 0.3;
        }
    }

    .btn-outline-primary {
        border-color: red;
        color: red;

        &:hover {
            background-color: red;
            border-color: red;
        }
    }
}