@import '../../common.scss';

.partners {
    text-align: center;
    padding: 100px 0;
    color: white;
    background-color: $dark-gray;

    .image-wrapper {
        height: 220px;
    }

    img {
        max-height: 200px;
        max-width: 200px;
    }

    h4 {
        text-transform: uppercase;
    }

}