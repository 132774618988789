.landing {

    .section-header {
        font-size: 60px;
        margin: 0;
        line-height: 60px;
        text-transform: capitalize;
    }

    .section-sub-header {
        color: red;
        margin: 10px 0;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
    }

    .style-line-red {
        height: 6px;
        width: 100px;
        background-color: red;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .transition {
        // display: block;
        width: 100%;
        height: 100px;
        background-color: black;

        img {
            width: 100%;
            height: 100%;
        }
    }

}